/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from '.';

export interface Citation {
  id: number;
  name: string;
  url: string;
  snippet: string;
}

const initialState = {
  citations: [] as Citation[],
  currentCitationId: -1,
  aiMessageLoading: false,
};

export type EditorState = typeof initialState;

const insertBuffer = (buffer: string, padding: number) => {
  const tweakedBuffer = buffer
    .replaceAll(/\[citation:(\d+)\]/g, (_, p1) => {
      const paddedCitationId = parseInt(p1, 10) + padding;
      return `<a href="" data-citation-id="${paddedCitationId}">[${paddedCitationId}]</a>`;
    })
    .replaceAll(/\n/g, '<br>');

  window.tinymce.activeEditor?.insertContent(tweakedBuffer, {
    format: 'raw',
  });
};

export const queryInsight = createAsyncThunk(
  'editor/queryInsight',
  async (query: string, { dispatch, getState }) => {
    const response = await fetch('https://search.b.woobledore.com/query', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query,
        lang: 'en',
        search_uuid: uuidv4(),
      }),
    });

    const reader = response.body.getReader();
    const decoder = new TextDecoder();

    let buffer = '';

    const state = getState() as RootState;
    const padding = state.editor.citations.length;
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      const chunk = decoder.decode(value, { stream: true });
      console.log('chunk: ', chunk);
      buffer += chunk;
      if (buffer.includes('__LLM_RESPONSE__')) {
        const [citationString, rest] = buffer.split('__LLM_RESPONSE__');
        buffer = rest;
        dispatch(editor.actions.appendCitations(JSON.parse(citationString)));
      }
      if (buffer.includes('__RELATED_QUESTIONS__')) {
        break;
      }
      if (!buffer.includes('\n')) {
        continue;
      }
      const breakIndex = buffer.lastIndexOf('\n');
      let currentBuffer = buffer;
      let nextBuffer = '';
      if (breakIndex !== -1) {
        currentBuffer = buffer.substring(0, breakIndex + 1);
        nextBuffer = buffer.substring(breakIndex + 1);
      }
      insertBuffer(currentBuffer, padding);
      buffer = nextBuffer;

      if (buffer.includes('__RELATED_QUESTIONS__')) {
        break;
      }
    }
    insertBuffer(buffer.split('__RELATED_QUESTIONS__')[0], padding);
  },
);

export const editor = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    clearAll: () => {
      return initialState;
    },
    appendCitations: (state, { payload }: PayloadAction<Citation[]>) => {
      payload.forEach((citation) => {
        if (!citation.id) {
          citation.id = state.citations.length + 1;
        }
        state.citations.push(citation);
      });
    },
    setCurrentCitationId: (state, { payload }: PayloadAction<number>) => {
      state.currentCitationId = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(queryInsight.pending, (state) => {
      state.aiMessageLoading = true;
    });
    builder.addCase(queryInsight.rejected, (state) => {
      state.aiMessageLoading = false;
    });
    builder.addCase(queryInsight.fulfilled, (state) => {
      state.aiMessageLoading = false;
    });
  },
});
